/* eslint-disable import/no-cycle */
/* eslint-disable unicorn/prefer-module */
import React, { lazy, Suspense, useEffect } from 'react';
import {
  useRoutes,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import LoadingScreen from './components/loading-screen';
import DashboardLayout from './layouts/dashboard';
import AuthLayout from './layouts/auth';
import { usePermissions } from './hooks';

const resolveElement = (Element) => {
  return <Element />;
};

const routesConfig = [
  {
    path: '401',
    element: resolveElement(lazy(() => import('./pages/errors/unauthorized'))),
  },
  {
    path: '/*',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: resolveElement(lazy(() => import('./pages'))) },
      {
        path: 'instituicoes',
        element: resolveElement(lazy(() => import('./pages/instituicoes'))),
        permission: 'instituicoes.viewAny',
      },
      {
        path: 'instituicoes/novo',
        element: resolveElement(lazy(() => import('./pages/instituicoes/new'))),
        permission: 'instituicoes.create',
      },
      {
        path: 'instituicoes/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/instituicoes/edit')),
        ),
        permission: 'instituicoes.update',
      },
      {
        path: 'planos-pagamentos',
        element: resolveElement(
          lazy(() => import('./pages/planos-pagamentos')),
        ),
        permission: 'planosPagamentos.viewAny',
      },
      {
        path: 'planos-pagamentos/novo',
        element: resolveElement(
          lazy(() => import('./pages/planos-pagamentos/new')),
        ),
        permission: 'planosPagamentos.create',
      },
      {
        path: 'planos-pagamentos/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/planos-pagamentos/edit')),
        ),
        permission: 'planosPagamentos.update',
      },
      {
        path: 'planos-pagamentos/:id',
        element: resolveElement(
          lazy(() => import('./pages/planos-pagamentos/show')),
        ),
        permission: 'planosPagamentos.viewAny',
      },
      {
        path: 'ordem-servico',
        element: resolveElement(lazy(() => import('./pages/ordem-servico'))),
        permission: 'planosPagamentos.viewAny',
      },
      {
        path: 'ordem-servico/:id',
        element: resolveElement(
          lazy(() => import('./pages/ordem-servico/show')),
        ),
        permission: 'planosPagamentos.viewAny',
      },
      {
        path: 'acordo',
        element: resolveElement(lazy(() => import('./pages/acordo'))),
        permission: 'planosPagamentos.viewAny',
      },
      {
        path: 'acordo/:id',
        element: resolveElement(lazy(() => import('./pages/acordo/show'))),
        permission: 'planosPagamentos.viewAny',
      },
      {
        path: 'acordo/novo',
        element: resolveElement(lazy(() => import('./pages/acordo/new'))),
        permission: 'planosPagamentos.create',
      },
      {
        path: 'cursos',
        element: resolveElement(lazy(() => import('./pages/cursos'))),
        permission: 'cursos.viewAny',
      },
      {
        path: 'cursos/novo',
        element: resolveElement(lazy(() => import('./pages/cursos/new'))),
        permission: 'cursos.create',
      },
      {
        path: 'cursos/:id',
        element: resolveElement(lazy(() => import('./pages/cursos/show'))),
        permission: 'cursos.viewAny',
      },
      {
        path: 'cursos/:id/editar',
        element: resolveElement(lazy(() => import('./pages/cursos/edit'))),
        permission: 'cursos.update',
      },
      {
        path: 'periodos-letivos',
        element: resolveElement(lazy(() => import('./pages/periodos-letivos'))),
        permission: 'periodosLetivos.viewAny',
      },
      {
        path: 'periodos-letivos/novo',
        element: resolveElement(
          lazy(() => import('./pages/periodos-letivos/new')),
        ),
        permission: 'periodosLetivos.create',
      },
      {
        path: 'periodos-letivos/:id',
        element: resolveElement(
          lazy(() => import('./pages/periodos-letivos/show')),
        ),
        permission: 'periodosLetivos.viewAny',
      },
      {
        path: 'periodos-letivos/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/periodos-letivos/edit')),
        ),
        permission: 'periodosLetivos.update',
      },
      {
        path: 'series',
        element: resolveElement(lazy(() => import('./pages/series'))),
        permission: 'series.viewAny',
      },
      {
        path: 'series/novo',
        element: resolveElement(lazy(() => import('./pages/series/new'))),
        permission: 'series.create',
      },
      {
        path: 'series/:id',
        element: resolveElement(lazy(() => import('./pages/series/show'))),
        permission: 'series.viewAny',
      },
      {
        path: 'series/:id/editar',
        element: resolveElement(lazy(() => import('./pages/series/edit'))),
        permission: 'series.update',
      },
      {
        path: 'disciplinas',
        element: resolveElement(lazy(() => import('./pages/disciplinas'))),
        permission: 'disciplinas.viewAny',
      },
      {
        path: 'disciplinas/novo',
        element: resolveElement(lazy(() => import('./pages/disciplinas/new'))),
        permission: 'disciplinas.create',
      },
      {
        path: 'disciplinas/:id',
        element: resolveElement(lazy(() => import('./pages/disciplinas/show'))),
        permission: 'disciplinas.viewAny',
      },
      {
        path: 'disciplinas/:id/editar',
        element: resolveElement(lazy(() => import('./pages/disciplinas/edit'))),
        permission: 'disciplinas.update',
      },
      {
        path: 'modelos-educacionais',
        element: resolveElement(
          lazy(() => import('./pages/modelos-educacionais')),
        ),
        permission: 'modelosEducacionais.viewAny',
      },
      {
        path: 'modelos-educacionais/novo',
        element: resolveElement(
          lazy(() => import('./pages/modelos-educacionais/new')),
        ),
        permission: 'modelosEducacionais.create',
      },
      {
        path: 'modelos-educacionais/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/modelos-educacionais/edit')),
        ),
        permission: 'modelosEducacionais.update',
      },
      {
        path: 'periodos',
        element: resolveElement(lazy(() => import('./pages/periodos'))),
        permission: 'periodos.viewAny',
      },
      {
        path: 'periodos/novo',
        element: resolveElement(lazy(() => import('./pages/periodos/new'))),
        permission: 'periodos.create',
      },
      {
        path: 'periodos/:id/editar',
        element: resolveElement(lazy(() => import('./pages/periodos/edit'))),
        permission: 'periodos.update',
      },
      {
        path: 'alunos',
        element: resolveElement(lazy(() => import('./pages/alunos'))),
        permission: 'alunos.viewAny',
      },
      {
        path: 'alunos/novo',
        element: resolveElement(lazy(() => import('./pages/alunos/new'))),
        permission: 'alunos.create',
      },
      {
        path: 'alunos/:id',
        element: resolveElement(lazy(() => import('./pages/alunos/show'))),
        permission: 'alunos.viewAny',
      },
      {
        path: 'alunos/:id/editar',
        element: resolveElement(lazy(() => import('./pages/alunos/edit'))),
        permission: 'alunos.update',
      },
      {
        path: 'professores',
        element: resolveElement(lazy(() => import('./pages/professores'))),
        permission: 'professores.viewAny',
      },
      {
        path: 'professores/novo',
        element: resolveElement(lazy(() => import('./pages/professores/new'))),
        permission: 'professores.create',
      },
      {
        path: 'professores/:id',
        element: resolveElement(lazy(() => import('./pages/professores/show'))),
        permission: 'professores.viewAny',
      },
      {
        path: 'professores/:id/editar',
        element: resolveElement(lazy(() => import('./pages/professores/edit'))),
        permission: 'professores.update',
      },
      {
        path: 'grades',
        element: resolveElement(lazy(() => import('./pages/grades'))),
        permission: 'grades.viewAny',
      },
      {
        path: 'grades/novo',
        element: resolveElement(lazy(() => import('./pages/grades/new'))),
        permission: 'grades.create',
      },
      {
        path: 'grades/:id',
        element: resolveElement(lazy(() => import('./pages/grades/show'))),
        permission: 'grades.viewAny',
      },
      {
        path: 'grades/:id/editar',
        element: resolveElement(lazy(() => import('./pages/grades/edit'))),
        permission: 'grades.update',
      },
      {
        path: 'turmas',
        element: resolveElement(lazy(() => import('./pages/turmas'))),
        permission: 'turmas.viewAny',
      },
      {
        path: 'turmas/novo',
        element: resolveElement(lazy(() => import('./pages/turmas/new'))),
        permission: 'turmas.create',
      },
      {
        path: 'turmas/:id',
        element: resolveElement(lazy(() => import('./pages/turmas/show'))),
        permission: 'turmas.viewAny',
      },
      {
        path: 'turmas/:id/editar',
        element: resolveElement(lazy(() => import('./pages/turmas/edit'))),
        permission: 'turmas.update',
      },
      {
        path: 'matricula-situacoes',
        element: resolveElement(
          lazy(() => import('./pages/matricula-situacoes')),
        ),
        permission: 'matriculaSituacoes.viewAny',
      },
      {
        path: 'matricula-situacoes/novo',
        element: resolveElement(
          lazy(() => import('./pages/matricula-situacoes/new')),
        ),
        permission: 'matriculaSituacoes.create',
      },
      {
        path: 'matricula-situacoes/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/matricula-situacoes/edit')),
        ),
        permission: 'matriculaSituacoes.update',
      },
      {
        path: 'resultados-academicos',
        element: resolveElement(
          lazy(() => import('./pages/resultados-academicos')),
        ),
        permission: 'resultadosAcademicos.viewAny',
      },
      {
        path: 'resultados-academicos/novo',
        element: resolveElement(
          lazy(() => import('./pages/resultados-academicos/new')),
        ),
        permission: 'resultadosAcademicos.create',
      },
      {
        path: 'resultados-academicos/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/resultados-academicos/edit')),
        ),
        permission: 'resultadosAcademicos.update',
      },
      {
        path: 'matriculas',
        element: resolveElement(lazy(() => import('./pages/matriculas'))),
        permission: 'matriculas.viewAny',
      },
      {
        path: 'matriculas/novo',
        element: resolveElement(lazy(() => import('./pages/matriculas/new'))),
        permission: 'matriculas.create',
      },
      {
        path: 'matriculas/:id',
        element: resolveElement(lazy(() => import('./pages/matriculas/show'))),
        permission: 'matriculas.viewAny',
      },
      {
        path: 'matriculas/:id/editar',
        element: resolveElement(lazy(() => import('./pages/matriculas/edit'))),
        permission: 'matriculas.update',
      },
      {
        path: 'enturmamento-alunos',
        element: resolveElement(
          lazy(() => import('./pages/turma-grade-alunos')),
        ),
        permission: 'enturmamentoAlunos.viewAny',
      },
      {
        path: 'enturmamento-alunos/novo',
        element: resolveElement(
          lazy(() => import('./pages/turma-grade-alunos/new')),
        ),
        permission: 'enturmamentoAlunos.create',
      },
      {
        path: 'enturmamento-alunos/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/turma-grade-alunos/edit')),
        ),
        permission: 'enturmamentoAlunos.update',
      },
      {
        path: 'enturmamento-professores',
        element: resolveElement(
          lazy(() => import('./pages/turma-grade-professores')),
        ),
        permission: 'enturmamentoProfessores.viewAny',
      },
      {
        path: 'enturmamento-professores/novo',
        element: resolveElement(
          lazy(() => import('./pages/turma-grade-professores/new')),
        ),
        permission: 'enturmamentoProfessores.create',
      },
      {
        path: 'enturmamento-professores/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/turma-grade-professores/edit')),
        ),
        permission: 'enturmamentoProfessores.update',
      },
      {
        path: 'professor-tipos',
        element: resolveElement(lazy(() => import('./pages/professor-tipos'))),
        permission: 'professorTipos.viewAny',
      },
      {
        path: 'professor-tipos/novo',
        element: resolveElement(
          lazy(() => import('./pages/professor-tipos/new')),
        ),
        permission: 'professorTipos.create',
      },
      {
        path: 'professor-tipos/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/professor-tipos/edit')),
        ),
        permission: 'professorTipos.update',
      },
      {
        path: 'pessoas',
        element: resolveElement(lazy(() => import('./pages/pessoas'))),
        permission: 'pessoas.viewAny',
      },
      {
        path: 'pessoas/novo',
        element: resolveElement(lazy(() => import('./pages/pessoas/new'))),
        permission: 'pessoas.create',
      },
      {
        path: 'pessoas/:id',
        element: resolveElement(lazy(() => import('./pages/pessoas/show'))),
        permission: 'pessoas.viewAny',
      },
      {
        path: 'pessoas/:id/editar',
        element: resolveElement(lazy(() => import('./pages/pessoas/edit'))),
        permission: 'pessoas.update',
      },
      {
        path: 'componentes-avaliacao',
        element: resolveElement(
          lazy(() => import('./pages/componentes-avaliacao')),
        ),
        permission: 'componentesAvaliacao.viewAny',
      },
      {
        path: 'componentes-avaliacao/novo',
        element: resolveElement(
          lazy(() => import('./pages/componentes-avaliacao/new')),
        ),
        permission: 'componentesAvaliacao.create',
      },
      {
        path: 'componentes-avaliacao/:id/editar',
        element: resolveElement(
          lazy(() => import('./pages/componentes-avaliacao/edit')),
        ),
        permission: 'componentesAvaliacao.update',
      },
      {
        path: 'processos-seletivos',
        element: resolveElement(
          lazy(() => import('./pages/processos-seletivos')),
        ),
        permission: 'processosSeletivos.viewAny',
      },
      {
        path: 'processos-seletivos/novo',
        element: resolveElement(
          lazy(() => import('./pages/processos-seletivos/new')),
        ),
        permission: 'processosSeletivos.create',
      },
      {
        path: 'processos-seletivos/:id/editar/resgate',
        element: resolveElement(
          lazy(() => import('./pages/processos-seletivos/edit')),
        ),
        permission: 'processosSeletivos.update',
      },
      {
        path: 'inscricoes',
        element: resolveElement(lazy(() => import('./pages/inscricoes'))),
        permission: 'inscricoes.viewAny',
      },

      {
        path: 'usuarios',
        element: resolveElement(lazy(() => import('./pages/usuarios'))),
        permission: 'users.viewAny',
      },
      {
        path: 'usuarios/novo',
        element: resolveElement(lazy(() => import('./pages/usuarios/new'))),
        permission: 'users.create',
      },
      {
        path: 'usuarios/:id/editar',
        element: resolveElement(lazy(() => import('./pages/usuarios/edit'))),
        permission: 'users.update',
      },
      {
        path: 'funcoes',
        element: resolveElement(lazy(() => import('./pages/funcoes'))),
        permission: 'roles.viewAny',
      },
      {
        path: 'funcoes/novo',
        element: resolveElement(lazy(() => import('./pages/funcoes/new'))),
        permission: 'roles.create',
      },
      {
        path: 'funcoes/:id/editar',
        element: resolveElement(lazy(() => import('./pages/funcoes/edit'))),
        permission: 'roles.update',
      },
      {
        path: 'auditoria',
        element: resolveElement(lazy(() => import('./pages/audits'))),
        permission: 'audits.viewAny',
      },
      {
        path: 'progressao-alunos',
        element: resolveElement(
          lazy(() => import('./pages/processos/progressao-alunos')),
        ),
        permission: 'processos.progressaoAlunos',
      },
      {
        path: 'apuracao-resultados',
        element: resolveElement(
          lazy(() => import('./pages/processos/apuracao-resultados')),
        ),
        permission: 'processos.apuracaoResultados',
      },
      {
        path: '403',
        element: resolveElement(lazy(() => import('./pages/errors/forbidden'))),
      },
      {
        path: '404',
        element: resolveElement(lazy(() => import('./pages/errors/not-found'))),
      },
      {
        path: '500',
        element: resolveElement(
          lazy(() => import('./pages/errors/internal-error')),
        ),
      },
      {
        path: '*',
        element: resolveElement(lazy(() => import('./pages/errors/not-found'))),
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: resolveElement(lazy(() => import('./pages/auth/login'))),
      },
      {
        path: 'esqueci-minha-senha',
        element: resolveElement(
          lazy(() => import('./pages/auth/forgot-password')),
        ),
      },
      {
        path: 'callback',
        element: resolveElement(
          lazy(() => import('./pages/auth/social-login')),
        ),
      },
      {
        path: '*',
        element: resolveElement(lazy(() => import('./pages/errors/not-found'))),
      },
    ],
  },
  {
    path: 'processo-seletivo/:slug/inscricao',
    element: resolveElement(lazy(() => import('./pages/guest/inscricao'))),
  },

  // rota para teste
  {
    path: 'processo-seletivo/:slug/inscricao-nova',
    element: resolveElement(
      lazy(() => import('./pages/guest/inscricao-pagamento')),
    ),
  },
  {
    path: 'checkout',
    element: resolveElement(lazy(() => import('./pages/guest/checkout'))),
  },
  {
    path: 'checkout/:idAcordo',
    element: resolveElement(lazy(() => import('./pages/guest/checkout'))),
  },
  {
    path: 'checkout-finalizado',
    element: resolveElement(
      lazy(() => import('./pages/guest/checkout-finalizado')),
    ),
  },
  {
    path: 'aceite-finalizado',
    element: resolveElement(
      lazy(() => import('./pages/guest/aceite-finalizado')),
    ),
  },

  {
    path: 'matriculaonline/planos',
    element: resolveElement(lazy(() => import('./pages/guest/planos'))),
  },

  {
    path: 'matriculaonline',
    element: resolveElement(
      lazy(() => import('./pages/guest/matriculaonline')),
    ),
  },

  {
    path: 'aceite-contrato',
    element: resolveElement(
      lazy(() => import('./pages/guest/aceite-contrato')),
    ),
  },
];

function Routes() {
  const element = useRoutes(routesConfig);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hasPermission } = usePermissions();
  const currentMatch = routesConfig[1].children.find(
    (route) => !!matchPath(route.path, pathname),
  );
  const isForbidden =
    currentMatch?.permission && !hasPermission(currentMatch.permission);

  useEffect(() => {
    if (isForbidden) {
      navigate('/403');
    }
  }, [isForbidden, navigate]);

  return <Suspense fallback={<LoadingScreen />}>{element}</Suspense>;
}

export { routesConfig as routes };

export default Routes;
